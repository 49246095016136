<template>
    <div class="wrapper flex flex-column">
        <Header :navList="navList" navCurrentId="4" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div v-if="subjectList.length" class="container filter-wrapper">
                <filter-tab title="学科" :list="subjectList" :isShowMore="true" :selected="subjectIndex" @clickItem="handleFilterEvent('subjectIndex',$event)"></filter-tab>
            </div>

             <!-- 筛选条件 -->
            <div v-if="subjectIndex != -1 " class="filter-condition active">
                <div class="container flex y-center x-left">
                    <div class="search-text">搜索条件：</div>
                    <a-breadcrumb class="search-breadcrumb">
                        <a-breadcrumb-item v-if="subjectIndex != -1">{{ subjectList[subjectIndex].operate_name }}
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                    <a-button class="clear-btn" @click="handleClearEvent"
                        style="margin-left:20px;" size="small">清除</a-button>
                </div>
            </div>
            <div v-else class="filter-condition"></div>
            
            <div class="live-content container flex">
                <div class="live-list flex-item ">
                    <div class="flex-item flex filtering x-left mb20 font16 pt10 pb10">
                        <div @click="handleTabEvent(1)" :class="{'active' : current_filter == 1}" style="cursor:pointer;" class="ml30">
                             <span class="ant-dropdown-link">
                                综合排序
                                <a-icon type="swap" style="transform: rotate(90deg);"/>
                            </span>
                        </div>
                        <div @click="handleTabEvent(2)" :class="{'active' : current_filter == 2}" style="cursor:pointer;" class="ml30">
                            <span class="ant-dropdown-link">
                                价格排序
                                <a-icon type="swap" style="transform: rotate(90deg);"/>
                            </span>
                        </div>
                        <div @click="handleTabEvent(3)" :class="{'active' : current_filter == 3}" style="cursor:pointer;" class="ml30">
                            <span class="ant-dropdown-link">
                                热度排序
                                <a-icon type="swap" style="transform: rotate(90deg);"/>
                            </span>
                        </div>
                    </div>
                    <a-spin :spinning="loading" tip="Loading...">
                        <div class="mh">
                            <div class="video-list">
                                <course-item width="230px" height="130px" @clickEvent="handleCourseEvent" :data-list="videoList" target-type="1">
                                </course-item>
                                <a-empty v-if="!videoList.length && !loading" />
                            </div>
                            <div class="t-c mt30" v-if="listTotal > 0">
                                <a-pagination :total="listTotal" :current="params.page" :page-size="params.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                            </div>
                        </div>
                    </a-spin>
                </div>
                <div class="live-right">
                    <template v-if="hotVideoList.length">
                        <common-title class="mt30" title="本周视频热播榜" :isMore="false"></common-title>
                        <div style="padding-bottom:20px;">
                            <template v-for="(item,index) in hotVideoList">
                                <router-link v-if="item.title" :to="'/course/play?id='+item.type_id" class="paper-writing y-center mb15" :key="index">
                                    <img v-if="index == 0" src="~@/assets/images/journal-skill1.png" alt="">
                                    <img v-if="index == 1" src="~@/assets/images/journal-skill2.png" alt="">
                                    <img v-if="index == 2" src="~@/assets/images/journal-skill3.png" alt="">
                                    <img style="width:15px" v-if="index > 2" src="~@/assets/images/journal-cardiovascular.png" alt="">
                                    <span :title="item.title" class="ml10 fs16 color333 row-1 t-l" :class="{'color666':index > 2}">{{item.title}}</span>
                                </router-link>
                            </template>
                        </div>
                    </template>    
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import commonTitle from '@/components/common-title';
import Header from '@/components/layout/layout-header';
import layoutRight from '@/components/layout/layout-right';
import Footer from '@/components/layout/layout-footer';
import filterTab from '@/components/filter-tab';
import textItem from '@/components/text-item';
import courseItem from '@/components/courser-list-item';
import { videoLogo, mediaNavList } from '@/config/const'
import { jsonFormat } from '@/utils/jsonFormat';
import storage from 'store';
import { mapState } from 'vuex';
let layoutRightConfig = {
    meeting: {
        show: true, // 是否显示
        title: '本月会议', // 标题
        methods: 'get',
        moreLink: '/meeting-list?year='+(new Date()).getFullYear()+'&month=' + ((new Date()).getMonth() + 1),
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 6,
            is_now_year_meeting: 1,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    }
};
export default {
    name: "VideoClass",
    components: {
        commonTitle,
        Header,
        layoutRight,
        filterTab,
        textItem,
        courseItem,
        Footer
    },
    data() {
        return {
            logoUrl: videoLogo,
            navList: mediaNavList,//顶部菜单
            // 学科筛选
            subjectIndex: -1,
            subject_id:'',
            subjectList: [],
            videoList: [],
            hotVideoList:[],  // 热们视频
            current_filter:1, // 热们视频筛选条件 // 排序规则 1综合排序 2 价格排序 3 热度排序
            layoutRightConfig: {},
            loading: true,
            // 精品课堂列表参数
            params: {
                page: 1,
                limit: 12,
                m_committee_id: 512, // 所属大商户id 默认512
            },
            listTotal: 0
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch:{
        '$route.query':{
            handler(){
                this.getSubjectList();
            },
            immediate:true,
        }
    },
    methods: {

        // 清除事件
        handleClearEvent() {
            this.handleFilterEvent('subjectIndex', -1);
        },
        // 筛选条件切换
        handleTabEvent(index){
            this.current_filter = index;
            this.getPremiumVideoList();
        },

        //视频与课程跳转事件
        handleCourseEvent(item){
            let type = this.$route.query.type || 1;
            // 跳转到视频
            if(type == 1){
                this.$router.push({
                    path:'/course/play?id='+item.id
                })
            // 跳转到课程
            }else{
                 this.$router.push({
                    path:'/course/classroom-details?id='+item.id
                })
            }
        },

        // 学科切换事件
        handleFilterEvent(key,e) {
            this.params.page = 1;
            key && (this[key] = e);
            let { subject_id } = this.$route.query;
            let subject_ids = this.subjectIndex == -1 ? subject_id : this.subjectList[this.subjectIndex].subject_ids;
            if(subject_ids){
                this.params.subject_ids = subject_ids
            }else{
                delete this.params.subject_ids;
            }
            // 更新右侧内容
            layoutRightConfig.meeting.data.subject_ids = subject_ids
            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));
            this.getPremiumVideoList();
        },
        pageInit() {
        },
        // 获取精品课堂视频列表
        getPremiumVideoList () {
            let data = {
                orderby_type:this.current_filter,
                ...this.params
            }
            let url = this.$route.query.type == 2 ? 'getCoursesList' : 'getPremiumVideoList';
            this.request.get(url, data).then((res) => {
                const { list, count }  = res.data
                list.forEach(it => {
                    it.img = it.default_pic_url;
                    it.video_count && (it.courseNum = it.video_count + '节');
                    if(it.power_type == 1){
                        it.price = '免费';
                    }
                });
                this.videoList = list;
                this.listTotal = Number(count)
                this.loading = false
            })
        },
        // 获取本周视频热播榜
        getHotVideoList(){
            this.request.get('getHotVideoList',{page:1,limit:5}).then(res => {
                this.hotVideoList = res.data;
            })
        },
         
        // 获取学科
        getSubjectList(){
            // url筛选学科
            let { subject_id } = this.$route.query;
            // 用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;
            this.$store.dispatch('getSubjectList').then(res => {
               // 学科
                let subjectList = [];
                res.data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })

                this.subjectList = subjectList;
                this.handleFilterEvent();
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getPremiumVideoList()
        },
    },
    created() {
        this.getHotVideoList();
    }
}
</script>

<style lang="less" scoped>
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}

.filter-wrapper {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}

.filter-condition {
    overflow: hidden;
    height: 8px;
    box-sizing: content-box;
    &.active {
        height: 50px;
        line-height: 50px;
    }
    /deep/.ant-breadcrumb>span:last-child {
        color: inherit;
    }
}


.live-content {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.filtering {
    background: #F9F9F9;
    .active {
        color: #1E51C9;
        font-weight: bold;
    }
}
.live-list {
    margin-right: 30px;

    .mh {
        min-height: 500px;
    }
}

// 直播推荐
.recommend-first {
    width: 50%;

    .rf-image {
        width: 100%;
        height: 208px;
    }
}

.recommend-list {
    width: calc(50% - 20px);
    margin-left: 20px;

    .recommend-item {
        padding-bottom: 7px;
        margin-top: 7px;
        display: block;
        border-bottom: 1px dashed #d8d8d8;

        &:last-child {
            padding-bottom: 0;
            border: 0;
        }

        &:nth-of-type(1) {
            margin-top: 0;
        }
    }
}

.live-right {
    width: 400px;

    .paper-writing {
        height: 24px;
        display: flex;
        cursor: pointer;
    }

    .apply-teacher {
        width: 100%;
        height: 92px;
    }
}
@media screen and (max-width:768px){
    .wrapper{
        .filter-wrapper{
            padding: 2px 0;
            border-radius: 0;
            position: sticky;
            top: 50px;
            z-index: 9;
        }
        .filter-condition.active{
            height:40px;
            line-height:40px;
            .container{
                padding:0 10px;
                display: flex;
            }
        }
        .live-content{
            display: block;
            padding: 0;
            padding: 20px 0 0;
            .live-list{
                width: 100%;
                .live-banner{
                    display: block;
                    width: 100%;
                    margin-bottom: 20px;
                    .recommend-first{
                        width: 100%;
                        height: auto;
                        .rf-image{
                            height: auto;
                        }
                    }
                    .recommend-list{
                        width: 100%;
                        height: auto;
                        margin-top: 20px;
                        margin-left: 0;
                        padding: 0 10px;
                    }
                }
                .video-list{
                    padding: 0 10px;
                }
            }
            .live-right{
                width: 100%;
                margin-top: 30px;
                padding: 0 10px;
            }
        }
    }
}
</style>